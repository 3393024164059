<template>
  <div id="single-file-upload">
    <div class="single-file-upload-wrapper">
      <h2>拖文件到任何地方来上传</h2>
      <p>或</p>
      <span class="fileinput-button">
        <span>上传</span>
        <input type="file" value="选择文件" multiple @change="handleFile">
      </span>
    </div>
  </div>
</template>

<script>
import Function from '@/utils/function'
export default {
  name: 'single-file-upload',
  props: {
  },
  data () {
    return {
    }
  },
  created () {},
  methods: {
    handleFile (e) {
      if (!Function.checkValue(e.target.files)) {
        let files = e.target.files
        let fileArr = []
        for (let i = 0; i <= files.length - 1; i++) {
          fileArr.push(files[i])
          if (i === files.length - 1) {
            this.$emit('update:singFile', fileArr)
            e.target.value = ''
          }
        }
      }
    },
    // 废弃
    downloads (src, name) {
      // 创建隐藏的可下载链接
      // let blob = 'http://pic.c-ctrip.com/VacationH5Pic/mice/wechat/ewm01.png'
      // var a = document.createElement('a')
      // a.style.display = 'none'
      // a.href = blob
      // a.download = 'QRcode.jpg'
      // document.body.appendChild(a)
      // a.click()
      // 移除元素
      // document.body.removeChild(a)
      // canvans下载
      var canvas = document.createElement('canvas')
      var img = document.createElement('img')
      img.onload = function () {
        canvas.width = img.width
        canvas.height = img.height
        var context = canvas.getContext('2d')
        context.drawImage(img, 0, 0, img.width, img.height)
        // window.navigator.msSaveBlob(canvas.msToBlob(),'image.jpg')
        // saveAs(imageDataUrl, '附件')
        canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height)
        canvas.toBlob((blob) => {
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = name
          link.click()
        }, 'image/jpeg')
      }
      img.setAttribute('crossOrigin', 'Anonymous')
      img.src = src
    }
  }
}
</script>

<style lang="scss" scoped>
  #single-file-upload {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .single-file-upload-wrapper {
      width: 80%;
      height: auto;
      position: relative;
      margin: 15% auto;
      text-align: center;
      h2 {
        font-size: 20px;
        color: #373d41;
      }
      p {
        color: #373d41;
        font-size: 14px;
        margin: 10px 0;
      }
      .fileinput-button {
        position: relative;
        display: inline-block;
        overflow: hidden;
        font-size: 14px;
        height: 46px;
        line-height: 44px;
        padding: 0 36px;
        border-width: 1px;
        border-style: solid;
        -webkit-appearance: none;
        border-radius: 3px;
        white-space: nowrap;
        box-sizing: border-box;
        color: #555;
        border-color: #ccc;
        background: #f7f7f7;
        box-shadow: 0 1px 0 #ccc;
        vertical-align: top;
        cursor: pointer;
        &:active {
          background: #eee;
          border-color: #999;
          box-shadow: inset 0 2px 5px -3px rgba(0,0,0,.5);
          -webkit-transform: translateY(1px);
          transform: translateY(1px);
        }
        &:hover {
          background: #fafafa;
          border-color: #999;
          color: #23282d;
        }
        input {
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0;
          font-size: 200px;
          cursor: pointer;
        }
      }
    }
  }
</style>
